import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import draggable from 'vuedraggable';
import moment from '@/plugins/moment';
import momentDurationFormatSetup from 'moment-duration-format'; /** USED! */
/** lodash */
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';
import differenceBy from 'lodash/differenceBy';
import difference from 'lodash/difference';
import uniqBy from 'lodash/uniqBy';
import omit from 'lodash/omit';
import groupBy from 'lodash/groupBy';
import unionBy from 'lodash/unionBy';
import isEqual from 'lodash/isEqual';
import { nanoid as uuidv4 } from 'nanoid';

Vue.config.productionTip = false;

Vue.prototype.$lodash = {
  cloneDeep,
  orderBy,
  differenceBy,
  uniqBy,
  omit,
  groupBy,
  unionBy,
  difference,
  isEqual,
};

moment.locale('de');

Vue.prototype.$moment = moment;
Vue.prototype.$uuid = uuidv4;

Vue.component('draggable', draggable);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');

import Vue from 'vue';
import Vuex from 'vuex';
import moment from '@/plugins/moment';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    message404: 'Not found',
    messages: [],
    appSearch: '',
    isAuthorized: false,
    apiFilters: {},
    apiAvailableFilters: {},
    apiTasks: {},
    user: {},
    profile: {},
    userToken: null,
    taskColorMap: {
      'A': 'red',
      'B': 'yellow darken-3',
      'C': 'blue',
      'D': 'grey',
      'P': 'green',
    },
    estimate: null,
    loading: [],
  },
  mutations: {
    SET_404_TEXT(state, data) {
      state.message404 = data;
    },
    SET_AUTH(state, data) {
      state.isAuthorized = data;
    },
    SET_FILTERS(state, data) {
      state.apiFilters = data;
    },
    SET_AVAILABLE_FILTERS(state, data) {
      state.apiAvailableFilters = data;
    },
    SET_USER(state, data) {
      state.user = data;
    },
    SET_PROFILE(state, data) {
      state.profile = data;
    },
    SET_USER_TOKEN(state, data) {
      state.userToken = data;
    },
    SET_API_TASKS(state, data) {
      state.apiTasks = data;
    },
    SET_SEARCH(state, data) {
      state.appSearch = data;
    },
    ADD_MESSAGE(state, data) {
      state.messages.push(data);
    },
    DEL_MESSAGE(state, id) {
      let ii = '';
      if (typeof id !== 'number' && id.auto ) {
        // auto del
        ii = id.id;
      } else {
        // manual del & clear timeout
        ii = id;
        const messageItem = state.messages.filter((el) => el.id === ii)[0];
        if (messageItem && messageItem.timer) {
          clearTimeout(messageItem.timer);
        }
      }
      state.messages = state.messages.filter((el) => el.id !== ii);
    },
    SET_ESTIMATE(state, estimate) {
      state.estimate = moment.duration(Number.parseInt(estimate), 's');
    },
    SET_LOADING(state, loading) {
      state.loading.push(loading)
    },
    DEL_LOADING(state, loading) {
      state.loading = state.loading.filter((l) => l !== loading)
    }
  },
  actions: {
    set_filters({ commit }, data) {
      // THIS IS ONLY FOR RAW DATA FROM BACKEND
      // for update filters use commit
      let radius = 0;
      let priority = [];
      try {
        radius = Math.max(...Object.entries(data.radius)
          .filter((el) => el[1] === 1)
          .map((el) => Number.parseInt(el[0])));
          //console.log(radius);
        priority = Object.entries(data.priority)
          .filter((el) => el[1] === 1)
          .map((el) => el[0]);
      } catch (error) {
        //
      }
      const filters = {
        radius,
        priority,
      }
      commit('SET_FILTERS', filters);
    },
    set_available_filters({ commit }, data) {
      const radius = Object.keys(data.radius);
      const priority = Object.keys(data.priority);
      commit('SET_AVAILABLE_FILTERS', { priority, radius });
    },
    get_filters({ commit }, data) {
      //
    },
    search_places({ commit }, data) {
      //
    },
    add_message({commit}, {message, color, timeout, infinity, button}) {
      if (!timeout) {
        timeout = 5000;
      }

      const id = this._vm.$uuid();

      const timer = infinity ? false : setTimeout(() => commit('DEL_MESSAGE', {id, auto: true}), timeout);
      commit('ADD_MESSAGE', {id, message, color, timeout, timer, button});
    }
  },
  modules: {
  },
});

import store from '@/store/index'

export const googleAuth = {
  apiKey: process.env.VUE_APP_GAPI_KEY,
  clientId: process.env.VUE_APP_GCLIENT_ID,
  scope: 'email \
profile \
https://www.googleapis.com/auth/profile.language.read \
openid \
https://www.googleapis.com/auth/userinfo.profile \
https://www.googleapis.com/auth/userinfo.email \
https://www.googleapis.com/auth/profile.agerange.read \
https://www.googleapis.com/auth/calendar.events',
  discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
};

let gAuth = {};
let gUser = {};
let gToken = null;

const getProfile = () => {
  let profile = {};
  try {
    const basic = gUser.getBasicProfile();
    profile = {
      name: basic.getName(),
      avatar: basic.getImageUrl(),
      email: basic.getEmail(),
    }
  } catch (error) {
    profile = {}
  }
  store.commit('SET_PROFILE', profile)
};

/** get google token */
const getGoogleToken = () => {
  try {
    const isAuthorized = gUser.hasGrantedScopes(googleAuth.scope);
    if (isAuthorized) {
      gToken = gUser.getAuthResponse().access_token;
    } else {
      gToken = null
    }
  } catch (error) {
    gToken = null
  }
}

/** gAuth update callback set gUser */
const updateSigninStatus = (isSignedIn) => {
  // get gUser
  if (isSignedIn) {
    gUser = gAuth.currentUser.get();
  } else {
    gUser = {};
  }
  // get profile
  getProfile()
  // get token
  getGoogleToken()
};

/** init google api client */
export const gapiInit = async (initCallback) => {
  store.commit('SET_LOADING', 'login.gapiInit')

  // gapi init auth
  await window.gapi.client.init({
    'apiKey': googleAuth.apiKey,
    'clientId': googleAuth.clientId,
    'scope': googleAuth.scope,
    'discoveryDocs': googleAuth.discoveryDocs,
  })
    .then()
    .catch((e) => {
      store.commit('DEL_LOADING', 'login.gapiInit')
      store.dispatch('add_message', {message: 'GAuth error', color: 'error', timeout: 5000});
      return Promise.reject(e)
    });

  gAuth = gapi.auth2.getAuthInstance();

  // Listen for sign-in state changes.
  gAuth.isSignedIn.listen((isSignedIn) => {
    updateSigninStatus(isSignedIn)
    initCallback(gToken)
  });

  updateSigninStatus(gAuth.isSignedIn.get())
  initCallback(gToken)

  if (!gToken) {
    store.dispatch('add_message', {message: 'Please, signin', color: 'info', timeout: 5000});
  }

  store.commit('DEL_LOADING', 'login.gapiInit')

  return gToken
};

export const gLogin = () => {
  try {
    gAuth.signIn()
  } catch (e) {
    store.dispatch('add_message', {message: 'GAuth: can\'t signin', color: 'error', timeout: 5000});
  }
};

export const logout = () => {
  //console.log(gUser);
 // if (Object.keys(gUser).length !=0 && gUser.isSignedIn()) {
  //  gAuth.signOut();
 // }
  store.commit('SET_USER', {});
  store.commit('SET_AUTH', false);
  store.commit('SET_USER_TOKEN', null);
  return null
};

<template>
  <v-toolbar-title  @click="extendSession()" v-if="user.email && estimate" class="mr-3">{{estimate.format('hh:mm:ss')}}</v-toolbar-title>
</template>
<script>
import { mapState } from 'vuex';
import { logout } from '@/services/auth';
import { authUrlRefresh } from '@/api';

export default {
  data: () => ({
    timer: null,
  }),
  computed: {
    ...mapState(['estimate', 'user', 'isAuthorized'])
  },
  watch: {
    user(user) {
      if (!user.email) return;
      this.startTimer();
    },
    isAuthorized(val) {
      if (!val) this.stopTimer()
    }
  },
  destroyed() {
    this.stopTimer();
  },
  methods: {
    extendSession(){
       authUrlRefresh(); 
    },
     startTimer() {
      // start timer
     
      if (this.timer) clearTimeout(this.timer);
      this.timer = setInterval(() => {
        if (this.estimate && this.estimate.asSeconds() > 0) {
          if(this.estimate.asSeconds()<300){
              //authUrlRefresh(); 
          }
          this.estimate.subtract(1, 's');
        } else this.stopTimer();
      }, 1000);
    },
    stopTimer() {
      clearTimeout(this.timer);
      if (this.isAuthorized) logout();
    },
  }
}
</script>

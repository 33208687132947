<template>
  <v-list class="pt-0">
    <v-subheader>Filters</v-subheader>
    <v-list-item dense>
      <v-list-item-title>Prios</v-list-item-title>
    </v-list-item>
    <v-list-item dense v-for="(item, i) in availableFilters.priority" :key="i">
      <v-checkbox class="mt-0" hide-details dense v-model="filters.priority" :label="item" :value="item"></v-checkbox>
    </v-list-item>
    <v-list-item dense>
      <v-list-item-title>
        Radius
        <v-chip color="primary" class="ml-2">{{filters.radius}}</v-chip>
      </v-list-item-title>
    </v-list-item>
    <v-list-item dense>
      <v-slider
        :value="availableFilters.radius.findIndex((el) => Number.parseInt(el) === filters.radius)"
        @input="(val) => filters.radius = Number.parseInt(availableFilters.radius[val])"
        thumb-label
        :max="availableFilters.radius.length ? availableFilters.radius.length - 1 : 0 "
        :min="0"
      >
        <template v-slot:thumb-label="{ value }">
          {{ availableFilters.radius[value] }}
        </template>
      </v-slider>
    </v-list-item>
    <v-divider></v-divider>
    <v-subheader>Heimadresse</v-subheader>

    <v-list-item link key="task-selected-home" v-if="!location.home.edit">
      <v-list-item-avatar size="24">
        <v-icon class="grey" size="16" color="white" >mdi-map-marker-up</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{user.location.address}}</v-list-item-title>
        <v-list-item-subtitle>Heimadresse</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon @click.stop="$set(location.home, 'edit', !location.home.edit)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-list-item dense v-else>
      <v-list-item-avatar size="24">
        <v-icon class="grey" size="16" color="white" >mdi-map-marker-up</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-autocomplete
          :hide-details="true"
          :items="predictions"
          item-text="description"
          :item-value="(item) => item"
          v-model="location.home.prediction"
          :search-input.sync="location.home.search"
          @change="$set(location.home, 'edit', !location.home.edit)"
          autofocus
          :loading="googleApis.loading"
          outlined
          dense
          flat
          label="Heimadresse"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-sheet class="caption">
                  neue Heimadresse eingeben
                </v-sheet>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-list-item-content>
    </v-list-item>
    <v-list-group v-if="false"
      prepend-icon="mdi-office-building-marker"
      value="true"
    >
      <template v-slot:activator>
        <v-list-item-title>{{user.location.address}}</v-list-item-title>
      </template>
    </v-list-group>
  </v-list>
</template>
<script>
import { mapState } from 'vuex';
import places from '@/mixins/places';
import { changeHomeAdress } from '@/api';
export default {
  mixins: [places],
  props: {
  },
  data() {
    return {
      filters: {
        priority: [],
        radius: 0,
        latitude: '',
        longitude: '',
      },
      availableFilters: {
        priority: [],
        radius: [],
      },
      location: {
        home: {
          prediction: null,
          search: '',
          edit: false,
        }
      }
    };
  },
  computed: {
    ...mapState(['apiFilters', 'apiAvailableFilters', 'user']),
    predictions() {
      const predictions = this.googleApis.predictions;
      return predictions || [];
    },
  },
  mounted(){
    this.loadMapScript()
      .then(() => {
        this.initMapPlaces();
      })
  },
  watch: {
    apiFilters: {
      handler() {
   
        this.filters = this.$lodash.cloneDeep(this.apiFilters);
      },
      immediate: true,
    },
    apiAvailableFilters: {
      handler() {
        this.availableFilters = this.$lodash.cloneDeep(this.apiAvailableFilters);
      },
      immediate: true,
    },
    'location.home.search': {
      handler(val) {
        if (val && val.length >= 3) {
          this.searchPlaces(val);
        }
      },
    },
    'location.home.prediction': {
      handler(val) {
        console.log('watch location.start.prediction')
       
        this.getLocationIncludingAdress(val.place_id)
          .then((res) => {
            console.log('watch location.start.prediction getLocation')
          //  this.userStart = res;
          console.log("json aaa:"+JSON.stringify(res));
          changeHomeAdress({plz:res.plz,ort:res.ort,strasse:res.strasse,lat:res.lat,lng:res.lng}) .then((res2) => {
            this.$emit('update:userStart', {lat:res.lat,lng:res.lng});
            this.$emit('update:userDestination', {lat:res.lat,lng:res.lng});
          });
          });
      }
    },
  },
  methods: {
    applyFilters() {
      this.$store.commit('SET_FILTERS', this.filters);
    }
  },
}
</script>
import axios from 'axios'

const protocol = `${process.env.VUE_APP_API_PROTOCOL}:` || global.window.location.protocol || 'http:';
const host = process.env.VUE_APP_API_HOST;
const path = process.env.VUE_APP_API_PATH;

const http = axios.create({
  baseURL: `${protocol}//${host}${path}`,
})

export { http };

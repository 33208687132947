<template>
  <v-app>
    <Drawer :drawer.sync="drawer" v-if="isAuthorized" />
    <v-app-bar
      app
      color="primary"
      dark
      clipped-left
    >
      <v-app-bar-nav-icon @click="drawer = !drawer" v-if="isAuthorized"></v-app-bar-nav-icon>
      <!-- <v-toolbar-title>Application</v-toolbar-title> -->

      <v-spacer></v-spacer>

      <v-scroll-y-transition mode="out-in" v-if="isAuthorized" >
        <v-btn class="mr-3" v-if="!searchActive" text @click="searchActive = !searchActive">
          Search{{search ? `: "${search}"` : ''}}
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-text-field
          autofocus
          v-else
          clearable
          v-model.trim="search"
          label="Search"
          hide-details
          single-line
          class="mr-3"
          @blur="() => searchActive = false"
        />
      </v-scroll-y-transition>
      <Estimate />
      <Login :opened="modals.login"/>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <!-- messages -->
    <v-snackbar
      app
      right
      :timeout="-1"
      :value="$store.state.messages.length"
      content-class="pa-0"
      color="transparent"
      elevation="0"
    >
      <v-alert width="100%" :color="item.color" v-for="(item, i) in $store.state.messages" :key="i">
        <div v-html="item.message"></div>
        <template v-slot:close>
          <v-btn :icon="!item.button" :text="!!item.button" small @click="$store.commit('DEL_MESSAGE', item.id)">
            <v-icon v-if="!item.button">mdi-close</v-icon>
            <span v-else>{{item.button}}</span>
          </v-btn>
        </template>
      </v-alert>
    </v-snackbar>
  </v-app>
</template>

<script>
import Login from '@/components/Login.vue';
import Drawer from '@/components/Drawer.vue';
import Estimate from '@/components/estimate';
import { mapState } from 'vuex';

export default {
  name: 'App',
  components: {
    Login,
    Drawer,
    Estimate,
  },
  data() {
    return {
      drawer: false,
      search: '',
      searchActive: false,
      modals: {
      },
    };
  },
  computed: {
    ...mapState(['user','isAuthorized']),
  },
  watch: {
    search(val) {
      this.$store.commit('SET_SEARCH', val);
    },
  },
  mounted() {
    const width = window.innerWidth;
    if (width > 900) {
      this.drawer = true;
    }
  },
  methods: {
  },
};
</script>

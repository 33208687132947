<template>
  <div>
    <v-btn v-if="isAuthorized" fab small title="logout" text class="mr-3" @click="locallogout"><v-icon>mdi-logout</v-icon></v-btn>
   <!-- <v-btn
      @click="gLogin"
      text
      v-else
      :loading="loading"
    >
      <span class="mr-2">Login</span>
      <v-icon>mdi-google</v-icon>
    </v-btn>
  
     <div id="g_id_onload" v-if="!isAuthorized"
         :data-client_id="googleAuth.clientId"
         data-callback="handleCredentialResponse">
    </div>-->
    <div v-show="!isAuthorized " >
      
<div  id="g_id_signin"></div>

    </div>

    <v-snackbar
      v-model="snackbar"
    >
      <div v-html="message"></div>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { authUrl } from '@/api';
import {  logout ,googleAuth} from '@/services/auth';

export default {
  data() {
    return {
      snackbar: false,
      message: ''
    };
  },
  computed: {
    ...mapState(['profile', 'isAuthorized']),
    loading() {
      return this.$store.state.loading.some((l) => /^login/.test(l))
    }
  },
  async mounted() {
     await this.externalScriptsGapi();
    await this.externalScripts();
   
        var self = this;
    window.onload = function () {
        window.gapi.load('client', function(){

           window.gapi.client.init({
    'apiKey': googleAuth.apiKey,
   // 'clientId': googleAuth.clientId,
   // 'scope': googleAuth.scope,
    'discoveryDocs': googleAuth.discoveryDocs,
  });});
 
    google.accounts.id.initialize({
      client_id: googleAuth.clientId,
      auto_select:false,
      callback: function(token){sessionStorage.setItem('googletoken',token.credential);self.apiLogin(token.credential);
     
      //      tokenClient.requestAccessToken({prompt: ''});


      }
    });

    
  self.renderGoogleButton();
  };
 this.apiLoginSession();
   // window.gapi.load('client:auth2', async () => {
     // const gToken = await gapiInit(this.apiLogin)
      //this.apiLogin(gToken) 
   // });
  },
  methods: {
    renderGoogleButton(){    
      
      google.accounts.id.renderButton(document.getElementById('g_id_signin'),   { theme: 'outline', size: 'large' });
      },
    locallogout(){
    this.logout();
    sessionStorage.setItem('googletoken','');
    
    },
  /** load google script */
    async externalScripts() {
      return new Promise((res, rej) => {
        this.$store.commit('SET_LOADING', 'login.externalScripts')
        // GAPI
        var gapiScript = document.createElement('script');
        gapiScript.type = 'text/javascript';
        gapiScript.src = 'https://accounts.google.com/gsi/client';
        gapiScript.onload = () => {
          this.$store.commit('DEL_LOADING', 'login.externalScripts')
          return res();
        };
        gapiScript.onerror = () => {
          this.$store.commit('DEL_LOADING', 'login.externalScripts')
          return rej();
        };
        document.body.appendChild(gapiScript);

      });
      },
       async externalScriptsGapi() {
      return new Promise((res, rej) => {
        this.$store.commit('SET_LOADING', 'login.externalScripts')
        // GAPI
        var gapiScript = document.createElement('script');
        gapiScript.type = 'text/javascript';
        gapiScript.src = 'https://apis.google.com/js/api.js';
 
        gapiScript.onload = () => {
          this.$store.commit('DEL_LOADING', 'login.externalScripts');
          return res();
        };
        gapiScript.onerror = () => {
          this.$store.commit('DEL_LOADING', 'login.externalScripts')
          return rej();
        };
        document.body.appendChild(gapiScript);

      });
      },
    logout,
    async apiLogin(gToken) {
      if (!gToken) {
        return null
      }
      try {
        const res = await authUrl(gToken);

        if (this.$route.path != '/') {
          this.$router.push('/');
        }
      } catch (error) {
        const status = error.response?.status;
        this.snackbar = true;
        switch (status) {
          case 404:
            const email = this.profile.email;
            this.message = `Benutzer <span class="v-chip error v-size--small">${email}</span> nicht gefunden. Bitte wenden Sie sich an den Administrator, um zu prüfen, ob Sie die erforderlichen Zugriffsrechte besitzen`;
            break;
          case 401:
            this.message = 'Unauthorized';
            break;
          default:
            this.message = error;
            break;
        }
        if (status === 500) {
          if (this.$route.path != '/unknown') {
            this.$router.push('/unknown');
          }
        } else if (this.$route.path != '/404') {
          this.$router.push('/404');
        }
        this.$store.commit('SET_404_TEXT', this.message);

        throw error;
      }
    },
    async apiLoginSession() {
    
      try {

        const googletoken=sessionStorage.getItem('googletoken');
        if(googletoken && googletoken!==''){
        const res = await authUrl(googletoken);
        }
      } catch (error) {
      }
    }
  },
};
</script>

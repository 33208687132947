<template>
  <v-navigation-drawer
    @input="(val) => $emit('update:drawer', val)"
    :value="drawer"
    color="white"
    clipped
    fixed
    light
    app
    height="100%"
  >
    <v-list v-if="isAuthorized">
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="title">{{user.firstname}}</v-list-item-title>
          <v-list-item-subtitle>{{user.email}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider v-if="isAuthorized"></v-divider>
    <Filters v-if="isAuthorized" ref="filter" />
    <template v-slot:append>
      <v-list-item>
        <v-btn block color="primary" @click="() => {if ($refs.filter) $refs.filter.applyFilters();}" class="my-4">Apply filter</v-btn>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>
<script>
import { mapState } from 'vuex';
import Filters from '@/components/Filters.vue';

export default {
  props: {
    drawer: Boolean,
  },
  components: {
    Filters,
  },
  data() {
    return {
      menu: [
      ],
    };
  },
  computed: {
    ...mapState(['isAuthorized', 'user']),
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  },
}
</script>
import { http } from './http'
import store from '@/store/index'

export const authUrlRefresh = async () => {
  store.commit('SET_LOADING', 'login.authUrl')
  const url = `/api/login/refresh?refreshtoken=${store.state.userToken}`;
  const res = await http.get(url)
    .catch((e) => {
      store.commit('DEL_LOADING', 'login.authUrl')
      return Promise.reject(e);
    });

  const token = res.headers['token'];
  store.commit('SET_USER_TOKEN', token);
  store.commit('SET_ESTIMATE', res.headers['token-lifetime']);

  store.commit('DEL_LOADING', 'login.authUrl')

  return res
}
export const authUrl = async (gToken) => {
  store.commit('SET_LOADING', 'login.authUrl')
  const url = `/api/login/google?token=${gToken}`;
  const res = await http.get(url)
    .catch((e) => {
      sessionStorage.setItem('googletoken','');
      store.commit('DEL_LOADING', 'login.authUrl')
      return Promise.reject(e);
    });

  store.dispatch('set_filters', res.data.filters);
  store.dispatch('set_available_filters', res.data.filters);
  store.commit('SET_USER', res.data.user);
  store.commit('SET_AUTH', true);

  const token = res.headers['token'];
  store.commit('SET_USER_TOKEN', token);
  store.commit('SET_ESTIMATE', res.headers['token-lifetime']);

  store.commit('DEL_LOADING', 'login.authUrl')

  return res
}

export const getTasksApi = async (params) => {
  const url = '/api/tasks/index'
  const headers = {
    //token: store.state.userToken,
  }
  params.token=store.state.userToken;

  if(params.priority){
    let p='';
    let i=0;
 
    for(let p1 in params.priority){
      if(i>0){
        p+=',';
      }
      p+=params.priority[p1];
      i++;
    }
    params.priority=p;
  }
  const res = await http.get(url, { params, headers });
  store.commit('SET_ESTIMATE', res.headers['token-lifetime']);
  return res
}

export const userTasksApi = async (params) => {
  const url = '/api/tasks/user'
  const headers = {
    //token: store.state.userToken,
  }
  params.token=store.state.userToken;
  
  const res = await http.get(url, { params, headers });
  store.commit('SET_ESTIMATE', res.headers['token-lifetime']);
  return res
}

export const tasksApplyApi = async (params) => {
  const url = '/api/tasks/apply/'
  const headers = {
    token: store.state.userToken,
  }
  const res = await http.post(url, params, { headers });
  store.commit('SET_ESTIMATE', res.headers['token-lifetime']);
  store.dispatch('add_message', {message: 'Route saved', color: 'success', infinity: true, button: 'ok'});
  return res
}


export const changeHomeAdress = async (params) => {
  const url = '/api/home/adress/'
  const headers = {
    token: store.state.userToken,
  }
  const res = await http.post(url, params, { headers });
  store.commit('SET_ESTIMATE', res.headers['token-lifetime']);
  store.commit('SET_USER', res.data.user);
  store.dispatch('add_message', {message: 'Heimadresse geändert', color: 'success', infinity: true, button: 'ok'});
  return res
}
